<template>
    <div class="scope-approvals">
        <ConfirmDialog ref="confirmDialog"/>

        <div class="scope-approvals__title">
            <h3 class="scope-approvals__font scope-approvals__font--h3">
                Internal Approval
            </h3>

            <CBadge class="scope-approvals__badge" :color="statusConfig.color" shape="rounded-pill"
                    :style="{ '--cui-dark': '#9FAFBC', '--cui-info': '#B12CF1', '--cui-success': '#0BBD99' }">
                {{ statusConfig.label }}
            </CBadge>
        </div>

        <div class="scope-approvals__tabs">
            <button :disabled="disabledScopeDraftTab" type="button"
                    class="scope-approvals__button scope-approvals__button--text scope-approvals__tab"
                    :class="{ 'scope-approvals__tab--active': activeTab === 'scope_draft' }"
                    @click="setActiveTab('scope_draft')">
                <span class="scope-approvals__font scope-approvals__font--p2">
                  Scope Draft
                </span>
            </button>

            <button :disabled="disabledFinalScopeApprovalTab" type="button"
                    class="scope-approvals__button scope-approvals__button--text scope-approvals__tab"
                    :class="{ 'scope-approvals__tab--active': activeTab === 'final_scope_approval' }"
                    @click="setActiveTab('final_scope_approval')">
                <span class="scope-approvals__font scope-approvals__font--p2">
                  Final Scope Approval
                </span>
            </button>
        </div>

        <div class="scope-approvals__chat">
            <span v-if="haveUnreadMessages && !disabledChatButton"
                  class="scope-approvals__font scope-approvals__font--text">
                You have a new message
            </span>

            <CButton :disabled="disabledChatButton || statusConfig.chatDisabled" size="sm" color="primary"
                     class="scope-approvals__button scope-approvals__button--chat" variant="outline" @click="openChat">
                <span class="scope-approvals__font scope-approvals__font--p1">Scope chat</span>
                <CIcon name="cilSpeech" class="scope-approvals__icon scope-approvals__icon--speech"/>
            </CButton>

            <ChatPopup :status="status" :visible="visibleChatPopup" :active-tab="activeTab"
                       :disabled-final-scope-approval-tab="disabledFinalScopeApprovalTab"
                       :disabled-scope-draft-tab="disabledScopeDraftTab" :have-unread-messages="haveUnreadMessages"
                       @closeChat="closeChat"/>
        </div>

        <div class="scope-approvals__content">
            <template v-if="!usersListsByActiveTab.length">
                <h3 class="scope-approvals__font scope-approvals__font--h3 text-center scope-approvals__heading">
                    Selected Workflow
                </h3>

                <p class="scope-approvals__font scope-approvals__font--p1 scope-approvals__font--gray text-center">
                    <span class="scope-approvals__font--black">{{ workflowName }}</span>
                    workflow is assigned to this
                    <router-link :to="{ name: 'ProjectScopeWorkflow', params: { id: $route.params.id } }">
                        project
                    </router-link>
                    . <br/><br/>
                    Workflows can be changed in project <br/>
                    and updated in
                    <router-link :to="''" @click="toSetting()">Settings</router-link>
                    . <br/>
                    Caution: these changes would affect <br/>
                    all the scopes of this project
                </p>
            </template>

            <UsersApprovalList v-else :users="usersListsByActiveTab"
                               :visible-actions="activeTab === 'final_scope_approval'"
                               :disable-action-button="disableActionApprovalButtons" @approveHandler="approveHandler"
                               @rejectHandler="rejectHandler"/>
        </div>

        <div class="scope-approvals__submit">
            <CButton color="primary" :disabled="statusConfig.buttonDisabled" @click="statusConfig.event">
                {{ statusConfig.buttonText }}
            </CButton>
        </div>

        <AppModal :visible="rePushCommitmentVisible" title="Re-Push Commitment"
                  text="Need to update documents with corrected information?" @close="toggleRePushCommitmentVisible">
            <template #default class="mt-2">
                <p class="text-information small mt-4">
                    Click confirm below to start the process of resending corrected documents.
                </p>
            </template>
            <template #footer>
                <CButton color="primary" variant="outline" :disabled="loadingRePushCommitment"
                         @click="toggleRePushCommitmentVisible">
                    Cancel
                </CButton>
                <SubmitBtn :loading="loadingRePushCommitment" color="primary" @click.prevent="rePushCommitment">
                    Confirm
                </SubmitBtn>
            </template>
        </AppModal>
    </div>
</template>

<script>
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import UsersApprovalList from './UsersApprovalList'
import ChatPopup from './ChatPopup'
import {notifyByPlan, permissionError} from '@/services/Notify/Toasts'
import Gate from '@/services/Gate/Gate'
import {mapGetters} from 'vuex'
import AppModal from "@/components/Modals/AppModal.vue";
import TaskStatusBadge from "@/components/Task/TaskStatusBadge.vue";
import SubmitBtn from "@/components/SubmitBtn.vue";
import Scopes from "@/api/v2/endpoints/Scopes";

export default {
    name: 'ScopeApprovals',
    components: {SubmitBtn, TaskStatusBadge, AppModal, ChatPopup, UsersApprovalList, ConfirmDialog},
    inject: ['toast'],
    props: {
        status: {
            type: String,
            default: 'default',
        },
        workflowName: {
            type: String,
            default: '',
        },
        chatsData: {
            type: Object,
            default: () => ({}),
        },
        scopeDraftUsers: {
            type: Array,
            default: () => [],
        },
        finalScopeApprovalUsers: {
            type: Array,
            default: () => [],
        },
        loadingApprovalData: {
            type: Boolean,
            default: false,
        },
        viewOnly: {
            type: Boolean,
            default: false,
        },
        scopeHasAssignedTasks: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['updateProjectData'],
    data() {
        return {
            visibleChatPopup: false,
            disableActionApprovalButtons: false,
            activeTab: '',
            disabledChat: false,
            sendContractModal: false,
            rePushCommitmentVisible: false,
            loadingRePushCommitment: false,
        }
    },
    computed: {
        ...mapGetters({
            permissions: 'scopePermissions',
        }),
        haveUnreadMessages() {
            return this.viewOnly
                ? this.chatsData?.approving
                : this.chatsData?.approving + this.chatsData?.editing
        },

        statusConfig() {
            const statuses = {
                default: {
                    label: 'Not Started',
                    color: 'dark',
                    buttonText: 'Send For Internal Approval',
                    buttonDisabled: true,
                    chatDisabled: true,
                    event: () => ({}),
                    activeTab: '',
                },
                drafting: {
                    label: 'Drafting',
                    color: 'dark',
                    buttonText: 'Send For Internal Approval',
                    buttonDisabled:
                        this.loadingApprovalData ||
                        this.disabledDraftSignatureButton,
                    chatDisabled: this.disabledChat,
                    event: this.sendToDraftSignature,
                    activeTab: 'scope_draft',
                },
                pending: {
                    label: 'Pending',
                    color: 'info',
                    buttonText: this.scopeHasAssignedTasks ? 'Re-Push Commitment' : 'Push Commitment',
                    buttonDisabled: true,
                    chatDisabled: this.disabledChat,
                    event: () => ({}),
                    activeTab: 'final_scope_approval',
                },
                'not sent': {
                    label: 'Approved',
                    color: 'success',
                    buttonText: 'Push Commitment',
                    buttonDisabled: this.loadingApprovalData,
                    chatDisabled: this.disabledChat,
                    event: this.sendCommitment,
                    activeTab: 'final_scope_approval',
                },
                'contract sent': {
                    label: 'Approved',
                    color: 'success',
                    buttonText: 'Re-Push Commitment',
                    buttonDisabled: this.loadingApprovalData || this.loadingRePushCommitmentTasks,
                    chatDisabled: this.disabledChat,
                    event: this.toggleRePushCommitmentVisible,
                    activeTab: 'final_scope_approval',
                },
            }

            return statuses[this.status || 'default'] || {}
        },
        disabledDraftSignatureButton() {
            return !(this.$route.params.scopeId && this.scopeDraftUsers.length)
        },
        disabledScopeDraftTab() {
            return (
                !this.permissions.chat ||
                !['drafting', 'pending', 'not sent', 'contract sent'].includes(this.status)
            )
        },
        disabledFinalScopeApprovalTab() {
            return !['pending', 'not sent', 'contract sent'].includes(this.status)
        },
        usersListsByActiveTab() {
            const dictionary = {
                scope_draft: 'scopeDraftUsers',
                final_scope_approval: 'finalScopeApprovalUsers',
            }

            return this[dictionary[this.activeTab]] || []
        },

        disabledChatButton() {
            return !this.permissions.chat || (this.disabledScopeDraftTab && this.disabledFinalScopeApprovalTab)
        },
    },
    watch: {
        status: {
            immediate: true,
            handler() {
                this.activeTab = this.statusConfig.activeTab || ''
            },
        },
    },
    methods: {
        async toSetting() {
            await Gate.can('projectView', 'setting')
                .then(response => {
                    if (response) {
                        return this.$router.push({name: 'SettingScopeApproval'})
                    }
                    this.$notify(permissionError)
                })
        },
        sendToDraftSignature() {
            if (this.viewOnly) {
                notifyByPlan()
                return
            }
            this.$refs.confirmDialog
                .confirm({
                    text: `Are you sure you want to send the scope for internal approval?`,
                    cancelText: 'Cancel',
                    confirmText: 'Yes',
                    reverse: true,
                })
                .then((response) => {
                    if (response) {
                        this.$http.scope
                            .scopeApprovalsCreate(this.$route.params.scopeId)
                            .then(({data}) => {
                                this.updateProjectData()
                                this.toast('success', data.message)
                            })
                            .catch((error) => {
                                this.toast('error', error.response.data.message)
                            })
                    }
                })
        },
        async sendCommitment() {
            const check = await Gate.can('edit', 'project', this.$route.params.id)

            if (!check) {
                notifyByPlan()
                return
            }

            this.goToProjectScopesTasksAssign();
        },
        rePushCommitment() {
            this.toggleLoadingRePushCommitment();

            Scopes
                .rePushCommitment(this.$route.params.scopeId)
                .then(() => this.goToProjectScopesTasksAssign())
                .catch(() => {
                    this.toast('error', 'Something wrong happened! Please, contact the support.');
                    this.toggleLoadingRePushCommitment();
                });
        },
        toggleRePushCommitmentVisible() {
            this.rePushCommitmentVisible = !this.rePushCommitmentVisible;
        },
        toggleLoadingRePushCommitment() {
            this.loadingRePushCommitment = !this.loadingRePushCommitment;
        },
        goToProjectScopesTasksAssign() {
            this.$router.push({
                name: 'ProjectScopesTasksAssign',
                params: {
                    id: this.$route.params.id,
                    scope_id: this.$route.params.scopeId,
                }
            })
        },
        setActiveTab(key) {
            this.activeTab = key
        },
        approveHandler() {
            const scopeId = this.$route.params.scopeId
            if (!scopeId) return
            this.disableActionApprovalButtons = true
            return this.$http.scope
                .scopeApprovalsApprove(scopeId)
                .then(({data}) => {
                    this.toast('success', data.message)
                    this.updateProjectData()
                })
                .finally(() => {
                    this.disableActionApprovalButtons = false
                })
        },
        rejectHandler() {
            const scopeId = this.$route.params.scopeId
            if (!scopeId) return
            this.disableActionApprovalButtons = true
            return this.$http.scope
                .scopeApprovalsReject(scopeId)
                .then(({data}) => {
                    this.toast('success', data.message)
                    this.updateProjectData()
                })
                .finally(() => {
                    this.disableActionApprovalButtons = false
                })
        },
        updateProjectData() {
            this.$emit('updateProjectData')
        },

        openChat() {
            this.visibleChatPopup = true
        },
        closeChat() {
            this.visibleChatPopup = false
        },

        chatDisabled() {
            this.chatDisabled = true
        },
    },
}
</script>

<style scoped lang="scss">
.scope-approvals {
    min-height: 534px;

    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid #c3cdd5;
    }

    &__tabs {
        margin-top: 9px;
        display: flex;
        justify-content: space-around;
        padding-bottom: 11px;
        border-bottom: 1px solid #c3cdd5;
    }

    &__icon {
        &--speech {
            margin-left: 8px;
        }
    }

    &__font {
        margin: 0;

        &--h3 {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
        }

        &--p1 {
            font-size: 14px;
            line-height: 20px;
        }

        &--p2 {
            font-size: 16px;
            line-height: 24px;
        }

        &--text {
            font-size: 12px;
            line-height: 20px;
            font-weight: 600;
            color: #db000b;
        }

        &--gray {
            color: #677a89;
        }

        &--black {
            color: #1c262f;
        }

        &--link {
            color: #0068ad;
            text-decoration: underline;
        }
    }

    &__button {
        &[disabled] {
            user-select: none;
            pointer-events: none;
            color: #9aa8b5;
        }

        &--text {
            background: transparent;
            border: none;
            padding: 0;
        }

        &--chat {
            margin-left: auto;
            border-radius: 8px;
            padding: 6px 20px;
        }
    }

    &__tab {
        color: #1c262f;

        &--disabled {
            color: #9aa8b5;
        }

        &--active {
            position: relative;

            &::after {
                position: absolute;
                content: '';
                bottom: -12px;
                left: 0;
                width: 100%;
                height: 2px;
                background: #0081c2;
            }
        }
    }

    &__chat {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }

    &__content {
        margin-top: 24px;
        flex-grow: 1;

        &--empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    &__loading {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__heading {
        margin-bottom: 12px;
    }

    &__submit {
        margin: 24px auto 0;
    }

    &__badge {
        padding: 2px 23px 4px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        letter-spacing: -0.003em;
    }
}
</style>
